import { useEffect, useRef, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { MembersImageProps } from '../../types';

function MemberLogoPicture({ image }: { image: MembersImageProps }) {
  // const ipxEndpoint = `/image/`;

  return (
    <picture>
      {/* <source
        type="image/avif"
        srcSet={`${ipxEndpoint}f_avif,w_300/${image.imageURL}`}
      />
      <source
        type="image/webp"
        srcSet={`${ipxEndpoint}f_webp,w_300/${image.imageURL}`}
      /> */}
      <img
        src={image.imageURL}
        alt=""
        loading="lazy"
        width={300}
        height={100}
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </picture>
  );
}

function MemberLogo({
  image,
  supportsLoading,
}: {
  image?: MembersImageProps;
  supportsLoading?: boolean;
}) {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
      setVisible(true);
      return;
    }

    const options = {
      root:
        typeof document !== 'undefined'
          ? document.querySelector('#members')
          : null,
      rootMargin: '100px',
      threshold: 0,
    };

    const el = ref.current;
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setVisible(entry.isIntersecting);
      }
    }, options);

    if (el) {
      observer.observe(el);
    }
    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [ref]);

  if (!image?.imageURL) {
    return null;
  }

  return (
    <li className="member">
      <div className="member__logo">
        {(supportsLoading && <MemberLogoPicture image={image} />) || (
          <div ref={ref} style={{ height: '100%', width: '100%' }}>
            {visible && <MemberLogoPicture image={image} />}
          </div>
        )}
      </div>
    </li>
  );
}

export default function MembersSection({
  images,
}: {
  images?: MembersImageProps[];
}) {
  const [supportsLoading, setSupportsLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSupportsLoading('loading' in HTMLImageElement.prototype);
    }
  }, []);

  return (
    <section id="members" className="container section-m-b members">
      <div className="row">
        <div className="col-xs-12 col-lg-10 col-lg-offset-1">
          <h2>CCCU Members</h2>
          <div className="narrow">
            <p>
              Integration of faith and learning. Unmistakable Christian
              formation. Service to the World.
            </p>
          </div>
          {images && (
            <div
              className="members__wrapper"
              style={
                {
                  '--animation-speed': `${images.length * 2.5}s`,
                } as React.CSSProperties
              }
            >
              <ul className="members__list">
                {images
                  .filter((_, i) => i % 2 === 0)
                  .map((image) => (
                    <MemberLogo
                      key={`member-${image.name}-1`}
                      image={image}
                      supportsLoading={supportsLoading}
                    />
                  ))}
                {images
                  .filter((_, i) => i % 2 === 0)
                  .map((image) => (
                    <MemberLogo
                      key={`member-${image.name}-3`}
                      image={image}
                      supportsLoading={supportsLoading}
                    />
                  ))}
              </ul>
              <ul className="members__list">
                {images
                  .filter((_, i) => i % 2 !== 0)
                  .map((image) => (
                    <MemberLogo
                      key={`member-${image.name}-2`}
                      image={image}
                      supportsLoading={supportsLoading}
                    />
                  ))}
                {images
                  .filter((_, i) => i % 2 !== 0)
                  .map((image) => (
                    <MemberLogo
                      key={`member-${image.name}-4`}
                      image={image}
                      supportsLoading={supportsLoading}
                    />
                  ))}
              </ul>
            </div>
          )}
          <div className="members__button-group button-group">
            <div className="button-group__item">
              {/* <a
                href="https://www.cccu.org/institutions/"
                className="button button--alt button--icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
                <span className="visually-hidden">
                  about the institutions affiliated with us
                </span>
                <BiLinkExternal />
              </a> */}
            </div>
            <div className="button-group__item">
              <a
                href="https://www.cccu.org/members_and_affiliates/"
                className="button button--alt button--icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                Search Institutions
                <BiLinkExternal />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
