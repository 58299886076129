import { graphql, Link, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import MembersSection from '../components/home/MembersSection';
import NewsMediaSection from '../components/home/NewsMediaSection';
import StudentStoriesSection from '../components/home/StudentStoriesSection';
import PostsGrid from '../components/PostsGrid';
import ReadMore from '../components/ReadMore';
import SEO from '../components/Seo';
import Subscribe from '../components/Subscribe';
import { MembersImageProps, PostEntryProps } from '../types';

interface DataProps {
  recentPosts?: {
    nodes?: PostEntryProps[];
  };
  stories?: {
    nodes?: PostEntryProps[];
  };
  membersImages?: {
    membersList?: MembersImageProps[];
  };
  featuredStories?: {
    acfOptionsFeaturedStories?: {
      featuredStories?: {
        featuredStories?: {
          story: PostEntryProps;
        }[];
      };
    };
  };
}

// function sortImages(images?: MembersImageProps[]) {
//   return images?.sort((a, b) => {
//     const bName = b.localFile?.name;
//     const aName = a.localFile?.name;
//     return aName && bName ? aName.localeCompare(bName) : 0;
//   });
// }

export function Head({ location }: { location: { pathname: string } }) {
  return (
    <SEO
      title="The Council for Christian Colleges & Universities"
      pathname={location.pathname}
      description="The Council for Christian Colleges & Universities (CCCU) is a higher education association of more than 185 Christian institutions globally."
    />
  );
}

export default function HomePage({ data }: PageProps<DataProps>) {
  const recentPosts = data.recentPosts?.nodes;
  const stories = data.stories?.nodes;
  const featuredStories =
    data.featuredStories?.acfOptionsFeaturedStories?.featuredStories?.featuredStories?.map(
      (node) => node.story
    ) || stories?.slice(0, 3);

  return (
    <main className="page-home">
      <section className="container hero">
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-5 col-lg-offset-1">
            <div className="hero__text__bg hide-xs show-md">
              <StaticImage
                src="../images/about-bg.png"
                layout="fullWidth"
                alt=""
                placeholder="none"
              />
            </div>
            <div className="hero__text section-p-t">
              <div className="hero__text__inner">
                <h1 className="hero__text__title">
                  Your Journey to Distinction.
                </h1>
                <div className="narrow">
                  <h2 className="h4">
                    Christian higher education is the catalyst to a fulfilling
                    life of loving God & your neighbor.
                  </h2>
                  <p>
                    Christian colleges and universities prepare graduates to
                    think critically, love extraordinarily, and serve
                    selflessly. Transition from college to career through a
                    world-class education.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="hero__img">
              <div className="hero__img__gradient gradient" />
              <StaticImage
                src="../images/hero-img.jpg"
                alt=""
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
      </section>
      {featuredStories && (
        <section className="container section-m-b recent">
          <div className="row">
            <div className="col-xs-12 col-md-11 col-lg-9 col-lg-offset-1">
              <div className="recent__inner push-left push-left-md-0 content-p-t">
                <h2 className="h3">Recent Student Stories</h2>
                <div className="recent__posts">
                  <PostsGrid posts={featuredStories} image excerpt social />
                </div>
                <div className="align-right">
                  <Link to="/videos" className="button">
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="container section-m-b intro">
        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="intro__inner content-p-t content-p-b push-right-md">
              <div className="intro__text ">
                <div className="narrow">
                  <h2 className="h3">
                    An extraordinary life is in your grasp.
                  </h2>
                  <ReadMore
                    options={[
                      {
                        breakpoint: 0,
                        maxLength: 1,
                      },
                      {
                        breakpoint: 768,
                        maxLength: false,
                      },
                    ]}
                  >
                    <p>
                      The uniqueness of Christian higher education also lies in
                      the bonds formed between students and faculty at each
                      university, as students find mentors for both their
                      education and faith formation in professors and leaders.
                    </p>
                    <p>
                      These relationships mold our students into individuals who
                      love God and their neighbor, serving as beacons of hope in
                      their communities throughout their lives.
                    </p>
                    <p>
                      Academic journeys begin from many different places and
                      settings, and the stories and videos on this page reveal
                      how a Christian college shaped these students for lives of
                      service and Christian witness in the world.
                    </p>
                  </ReadMore>
                </div>
              </div>
              <div className="intro__img">
                <div className="intro__img__inner">
                  <StaticImage
                    src="../images/intro-img.png"
                    alt=""
                    layout="fullWidth"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StudentStoriesSection
        className="stories"
        stories={stories}
        featuredStories={featuredStories}
      />
      <Subscribe className="section-m-b" />
      <MembersSection
        images={data.membersImages?.membersList?.sort((a, b) => {
          const bName = b.name;
          const aName = a.name;
          return aName && bName ? aName.localeCompare(bName) : 0;
        })}
      />
      <NewsMediaSection posts={recentPosts} />
      <section id="about" className="container about">
        <div className="row bottom-xs">
          <div className="col-xs-12 col-md-6 col-lg-5 col-lg-offset-1">
            <div className="about__text section-p-b">
              <div className="about__logo">
                <StaticImage
                  src="../images/logo-black.svg"
                  alt="CCCU"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
              <div className="narrow">
                <h2>About Us</h2>
                <p>
                  The Council for Christian Colleges & Universities is a higher
                  education association of more than 185 Christian institutions
                  around the world. Since 1976, the CCCU has served as the
                  leading national voice of Christian higher education.
                </p>
                <p>
                  With campuses across the globe, including more than 150 in the
                  U.S. and Canada and more than 30 from an additional 19
                  countries, CCCU institutions are accredited, comprehensive
                  colleges and universities whose missions are Christ-centered
                  and rooted in the historic Christian faith. Most also have
                  curricula rooted in the arts and sciences.
                </p>
                <p>
                  The CCCU’s mission is to advance the cause of Christ-centered
                  higher education and to help our institutions transform lives
                  by faithfully relating scholarship and service to biblical
                  truth.
                </p>
                <div className="align-right">
                  <a
                    href="https://www.cccu.org/"
                    className="button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit the CCCU
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about__img">
              <div className="about__img__bg">
                <StaticImage
                  src="../images/about-bg.png"
                  layout="fullWidth"
                  alt=""
                  placeholder="none"
                />
              </div>
              <StaticImage
                src="../images/about-img.png"
                layout="fullWidth"
                alt="Students"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export const query = graphql`
  query PostsQuery {
    recentPosts: allWpPost(limit: 8, sort: { order: DESC, fields: date }) {
      nodes {
        ...PostEntry
      }
    }
    stories: allWpStory(limit: 12, sort: { order: DESC, fields: date }) {
      nodes {
        ...StoryEntry
      }
    }
    membersImages: wp {
      membersList {
        imageURL
        name
      }
    }
    featuredStories: wp {
      acfOptionsFeaturedStories {
        featuredStories {
          featuredStories {
            story {
              ... on WpStory {
                ...StoryEntry
              }
            }
          }
        }
      }
    }
  }
`;
