import { useState } from 'react';
import { Link } from 'gatsby';
import { PostEntryProps } from '../../types';
import PostsGrid from '../PostsGrid';
import NoPostsWarning from '../NoPostsWarning';

// interface FormData {
//   search: string;
// }

export default function StudentStoriesSection({
  stories,
  featuredStories,
  className = '',
  layout,
}: // searchQuery,
{
  stories?: PostEntryProps[] | null;
  featuredStories?: PostEntryProps[] | null;
  className?: string;
  layout?: 'page';
  // searchQuery?: string;
}) {
  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm<FormData>();

  // const watchSearch = watch('search');

  const featuredIds = featuredStories
    ? featuredStories.map((story) => story.id)
    : [];

  const allStories =
    featuredStories && stories
      ? [
          ...stories.filter((story) => !featuredIds.includes(story.id)),
          ...featuredStories,
        ]
      : stories;

  const [posts, setPosts] = useState<PostEntryProps[] | null | undefined>(
    allStories
  );
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [error, setError] = useState<any>(null);

  // function searchPosts(query?: string) {
  //   if (!query) {
  //     return allStories;
  //   }

  //   return (
  //     stories?.filter((post) =>
  //       JSON.stringify(post).toLowerCase().includes(query.toLowerCase())
  //     ) || []
  //   );
  // }

  // const onSubmit = handleSubmit((data, e) => {
  //   e?.preventDefault();
  //   setPosts(searchPosts(data.search));
  // });

  // useEffect(() => {
  //   setPosts(searchPosts(watchSearch));
  // }, [watchSearch]);

  // useEffect(() => {
  //   setPosts(searchPosts(searchQuery));
  // }, []);

  return (
    <section className={`container section-m-b ${className}`}>
      <div className="row">
        <div className="col-xs-12 col-md-11 col-lg-10 col-lg-offset-1">
          {layout !== 'page' && <h2>Student Stories</h2>}
          <div
            className={`title-search ${
              layout === 'page' ? 'title-search--full-width' : ''
            }`}
          >
            <div className="title-search__inner">
              <div className="title-search__title ">
                {(layout === 'page' && (
                  <h1 className="h2">Student Stories</h1>
                )) || (
                  <h3 className="h4">Meet some Christian college students</h3>
                )}
              </div>
              {layout === 'page' && (
                <div className="title-search__description">
                  <div className="narrow">
                    <h2 className="h4">Meet some Christian college students</h2>
                    <p>
                      We think you’ll find these stories as inspiring as we do.
                      Our students and alumni know that no matter who they are
                      or where they come from, there’s a Christian college or
                      university that’s right for them.
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="title-search__form">
                <form onSubmit={onSubmit} className="searchform" action="/">
                  <input
                    type="search"
                    placeholder="Search Videos"
                    defaultValue={searchQuery}
                    {...register('search')}
                  />
                  <button type="submit" aria-label="Search">
                    <MdSearch />
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          {layout !== 'page' && (
            <div className="narrow">
              <p>
                We think you’ll find these stories as inspiring as we do. Our
                students and alumni know that no matter who they are or where
                they come from, there’s a Christian college or university that’s
                right for them.
              </p>
            </div>
          )}
          <div className="stories__posts content-m-t">
            {(posts?.length && (
              <PostsGrid posts={posts} image excerpt social />
            )) || <NoPostsWarning />}
            {layout !== 'page' && (
              <div
                className="stories__posts__more align-right"
                style={{ marginTop: '2rem' }}
              >
                <Link to="/videos" className="button">
                  View More
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
