import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { MdSearch } from 'react-icons/md';
import { PostEntryProps } from '../../types';
import PostsGrid from '../PostsGrid';

interface FormData {
  search?: string;
}

export default function NewsMediaSection({
  posts,
}: {
  posts?: PostEntryProps[];
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    navigate(`/news-press?search=${data.search}`, {
      replace: true,
      state: { isSearchQuery: true },
    });
  });

  if (!posts?.length) {
    return null;
  }

  return (
    <section className="container section-m-b news">
      <div className="row">
        <div className="col-xs-12 col-lg-10 col-lg-offset-1">
          <div className="title-search">
            <div className="title-search__inner">
              <div className="title-search__title">
                <h2>News / Press</h2>
              </div>
              <div className="title-search__form">
                <form
                  onSubmit={onSubmit}
                  className="searchform"
                  action="/news-press"
                >
                  <input
                    type="search"
                    placeholder="Search"
                    aria-invalid={!!errors.search}
                    {...register('search', { required: true })}
                  />
                  <button type="submit" aria-label="Search">
                    <MdSearch />
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="news__posts">
            <div className="news__posts__inner">
              <div className="news__posts__primary">
                <div className="news__posts__primary__top">
                  <PostsGrid posts={posts.slice(0, 2)} image excerpt meta />
                </div>
                <PostsGrid posts={posts.slice(2, 4)} meta />
              </div>
              <div className="news__posts__secondary">
                <PostsGrid posts={posts.slice(4, 8)} meta />
              </div>
            </div>
          </div>
          <div className="align-right">
            <Link to="/news-press" className="button">
              View More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
